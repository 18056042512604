import logo from './logo.svg';
import './App.css';
import { useState } from 'react';

function App() {

  const [showAboutMe, setShowAboutMe] = useState(false);
  const [style, setStyle] = useState("linksText");

  const handleShowAboutMe = () => {
    if (showAboutMe) {
      setShowAboutMe(false);
      setStyle("linksText");
    } else {
      setShowAboutMe(true);
      setStyle("highlight");
    }
  }

  return (
    <div class="vl">
      <div id="left"></div>
      <div id="right"></div>
      <div id="top"></div>
      <div id="bottom"></div>

      <div className="main">
        <div className="heading">
          <div> 🚧 </div>
          <div className="subhdr"> Hi there, this site is under construction :) </div>
          <div className="subhdr"> you can reach me here: </div>
        </div>
        <div>
          <div id="categories">
            <div className="links"> 💌 <span className="linksText"> <a href="mailto:rachelxzlim@gmail.com" className="linksText" target="_blank"> EMAIL </a>  </span>   </div>
            <div className="links" > 👾 <span className="linksText"> <a className="linksText" href="https://github.com/rachellxz" target="_blank"> GITHUB  </a> </span> </div>
            <div className="links"> 📝  <span className="linksText"> <a className="linksText" href="https://linkedin.com/in/rachellxz" target="_blank"> LINKED IN</a>  </span></div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default App;
